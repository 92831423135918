import React, { useEffect, useState } from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import headerStyles from './header.module.scss'
import Menu from '../menu/menu.component'
import Logo from './logo.svg'
import ThemeContext from '../../context/ThemeContext'
import Studio from './studio.svg'
import Close from '../close/close.component'
import MenuSecondary from '../menuSecondary/menu.secondary.component'
import { useScroll } from '../../hooks/scroll.hook'

export default function Header({
   hasBackground,
   isMenuWhite,
   goBack,
   secondary,
   menuData,
   colorHeader,
   isMoving,
   noBackgorund,
   hideStudioIcon,
   className,
   to,
   handleAudio,
   isMutted,
   currentPage,
}) {
   const { scrolling } = useScroll()
   const [pageName, setPageName] = useState('')

   useEffect(() => {
      let pageName = ''
      //console.log('currentPage', currentPage)
      switch (currentPage) {
         case 'Catalogue':
            pageName = 'Catalogue Raisonné'
            break
         case 'Museo':
            pageName = 'Museo'
            break
         case 'The Foundation':
            pageName = 'The Foundation'
            break
         case 'Contact Us':
            pageName = 'Contact Us'
            break
         default:
            pageName = ''
      }
      setPageName(pageName)
   }, [])

   const data = useStaticQuery(graphql`
      query {
         site {
            siteMetadata {
               title
            }
         }
      }
   `)

   return (
      <ThemeContext.Consumer>
         {theme => (
            <header
               id={headerStyles.mainHeader}
               className={`
            ${hasBackground ? '' : headerStyles.light} 
            ${isMoving && !theme.open ? headerStyles.light : ''}
            ${className ? className : ''}
          `}
               style={
                  secondary && colorHeader
                     ? { background: theme.open ? '' : colorHeader }
                     : {
                          //background: isMoving && !theme.open ? 'white' : '',
                          background:
                             scrolling || isMoving
                                ? theme.open
                                   ? ''
                                   : !noBackgorund
                                   ? isMenuWhite
                                      ? '#a77a74eb'
                                      : '#ffffffeb'
                                   : '#ffffffeb'
                                : '',
                       }
               }
            >
               <Menu
                  //hasBackground={
                  //(hasBackground && isMenuWhite && !scrolling && !isMoving) ||
                  //colorHeader
                  //}
                  hasBackground={(hasBackground && isMenuWhite) || colorHeader}
                  colorHeader={colorHeader}
                  className={headerStyles.headerNav}
                  onClickAudio={handleAudio}
                  isMutted={isMutted}
               />

               {!theme.open && (
                  <React.Fragment>
                     {!secondary ? (
                        <h1 className={headerStyles.logo}>
                           <Link
                              to={to ? to : '/'}
                              alt={data.site.siteMetadata.title}
                           >
                              <Logo
                                 className={
                                    hasBackground || colorHeader
                                       ? headerStyles.icon
                                       : headerStyles.iconDark
                                 }
                              />
                           </Link>
                        </h1>
                     ) : pageName ? (
                        <h1 className={headerStyles.title}>{pageName}</h1>
                     ) : (
                        <MenuSecondary
                           //hasBackground={(hasBackground && !isMoving) || colorHeader}
                           hasBackground={
                              (hasBackground && isMenuWhite) || colorHeader
                           }
                           menuData={menuData}
                           colorHeader={colorHeader}
                        />
                     )}
                     {!secondary && !pageName && (
                        <div className={headerStyles.right}>
                           {goBack ? (
                              <Close
                                 hasBackground={hasBackground && !scrolling}
                                 //onClick={goBack !== true ? goBack : null}
                              />
                           ) : (
                              <Link
                                 // add hideStudioIcon
                                 className={`${headerStyles.studio} ${
                                    hideStudioIcon ? headerStyles.hidden : ''
                                 }`}
                                 to='/studio/workshop'
                              >
                                 <Studio className={headerStyles.icon} />
                              </Link>
                           )}
                        </div>
                     )}
                  </React.Fragment>
               )}
            </header>
         )}
      </ThemeContext.Consumer>
   )
}
