import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import footerStyles from './footer.module.scss'

export default function Footer() {
  const {
    site: { siteMetadata },
    menu,
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          author
        }
      }

      menu: allMenuItems(sort: { order: DESC, fields: weight }) {
        nodes {
          id
          title
          url
          weight
          expanded
          menu_name
          childrenMenuItems {
            weight
            url
            title
          }
        }
      }
    }
  `)
  const footerMenu = menu.nodes.filter(
    node =>
      node.expanded === true &&
      node.menu_name === 'main' &&
      node.title !== 'Home'
  )
  const footerPrivacy = menu.nodes.filter(
    node => node.menu_name === 'privacy' && node.expanded !== true
  )

  const CustomLink = ({ onClick, to, children, activeClassName }) => {
    return to.includes('http') ? (
      <a target="_blank" rel="noreferrer" onClick={onClick} href={to}>
        {children}
      </a>
    ) : (
      <Link activeClassName={activeClassName ? activeClassName : ''} to={to}>
        {children}
      </Link>
    )
  }
  return (
    <footer id={footerStyles.mainFooter}>
      <div className={footerStyles.container}>
        <ul className={footerStyles.principalList}>
          {footerMenu.map(element => (
            <li key={element.id} className={footerStyles.column}>
              <h3>
                <CustomLink to={element.url}>{element.title}</CustomLink>
              </h3>
              <ul className={footerStyles.innerMenu}>
                {element.childrenMenuItems.map(sublink => (
                  <li key={sublink.url}>
                    <CustomLink
                      activeClassName={footerStyles.active}
                      to={sublink.url}
                    >
                      {sublink.title}
                    </CustomLink>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>

        <div className={footerStyles.bottom}>
          <div className={footerStyles.left}>
            <h4>© {siteMetadata.title}</h4>
            {footerPrivacy.map(element => (
              <Link
                key={element.url}
                activeClassName={footerStyles.active}
                to={element.url}
              >
                {element.title}
              </Link>
            ))}
          </div>
          <div className={footerStyles.author}>
            © Site by
            <a
              rel="noreferrer"
              style={{ marginLeft: 10 }}
              target="_blank"
              href="https://www.pastpresentfuture.com/"
            >
              {siteMetadata.author}
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}
