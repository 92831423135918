import React from 'react'
import contactStyles from './contact.module.scss'
import Container from '../container/container.component'
import Facebook from './facebook.svg'
import Instagram from './instagram.svg'
import Twitter from './twitter.svg'
import { Link } from 'gatsby'

export default function Contact() {
  return (
    <Container>
      <div className={contactStyles.container}>
        {/* Column 2 */}
        <div className={contactStyles.column}>
          <div className={contactStyles.wrapper}>
            <h2>Harriet & Esteban Vicente Foundation</h2>
            <p>100 Bradley Parkway Rm. 04 Blauvelt, NY 10913</p>
            {
              //<Link className={contactStyles.link} to="/">
              //Directions
              //</Link>
            }
          </div>
        </div>
        {/* End column 2 */}

        {/* Column 3 */}
        <div className={contactStyles.column}>
          <div className={contactStyles.wrapper}>
            <h2>Enquiries</h2>
            <ul>
              <li>
                <strong>General: </strong>
                <a href="mailto:info@vicentefoundation.org">
                  info@vicentefoundation.org
                </a>
              </li>
              <li>
                <strong>Media: </strong>
                <a href="mailto:media@vicentefoundation.org">
                  media@vicentefoundation.org
                </a>
              </li>
              <li>
                <a href="tel:+1.212.687.3233">+1.212.687.3233</a>
              </li>
            </ul>
          </div>
        </div>
        {/* End column 3 */}
        <ul className={contactStyles.socialMedia}>
          <li>
            <Instagram className={contactStyles.icon} />
          </li>
          <li>
            <Facebook className={contactStyles.icon} />
          </li>
          <li>
            <Twitter className={contactStyles.icon} />
          </li>
        </ul>
      </div>
    </Container>
  )
}
