import React, { useEffect, useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Hamburguer from '../hamburger/hamburguer.component'
import menuStyles from './menu.module.scss'
import ThemeContext from '../../context/ThemeContext'
import SoundOn from './sound_on.png'
import SoundOff from './sound_off.png'

// sort menu items
export default function Menu({
   hasBackground,
   className,
   colorHeader,
   onClickAudio = () => {},
   isMutted,
}) {
   const [currentUrl, setCurrentUrl] = useState('')
   useEffect(() => {
      const path = window.location.pathname.split('/')
      setCurrentUrl(path[1])
   }, [])

   const {
      allMenuItems: { nodes },
   } = useStaticQuery(graphql`
      query {
         allMenuItems(
            filter: { expanded: { eq: true } }
            sort: { order: DESC, fields: weight }
         ) {
            nodes {
               id
               title
               url
               weight
               expanded
               menu_name
               childrenMenuItems {
                  url
                  title
                  weight
               }
            }
         }
      }
   `)

   const toggleMutted = () => {
      onClickAudio(!isMutted)
   }

   return (
      <ThemeContext.Consumer>
         {theme => (
            <React.Fragment>
               {!theme.searchIsOpen && (
                  <Hamburguer
                     hasBackground={hasBackground && colorHeader !== '#fcf6f3'}
                     onClick={theme.toggleOpen}
                     styles={className}
                  />
               )}

               {currentUrl === 'home' && (
                  <button
                     tabIndex={0}
                     role='button'
                     className={menuStyles.audioIcon}
                     onClick={toggleMutted}
                     onKeyDown={toggleMutted}
                  >
                     <img
                        src={isMutted ? SoundOff : SoundOn}
                        alt='sound'
                        className={menuStyles.icon}
                     />
                  </button>
               )}
               <nav
                  className={`
              ${menuStyles.menu} 
              ${theme.open ? menuStyles.open : menuStyles.close}
              ${className}
            `}
               >
                  <div className={menuStyles.menuWrapper}>
                     <ul>
                        {nodes
                           .filter(node => node.menu_name === 'main')
                           .map(element => (
                              <li key={element.id}>
                                 {element.url.includes('http') ? (
                                    <a
                                       href={element.url}
                                       target='_blank'
                                       rel='noreferrer'
                                       onClick={theme.toggleOpen}
                                    >
                                       {element.title}
                                    </a>
                                 ) : (
                                    <>
                                       <Link
                                          onClick={theme.toggleOpen}
                                          activeClassName={menuStyles.active}
                                          to={element.url}
                                       >
                                          {element.title}
                                       </Link>

                                       {element.url === `/${currentUrl}` &&
                                          element?.childrenMenuItems?.map(
                                             item => (
                                                <li key={item.title}>
                                                   <Link
                                                      onClick={theme.toggleOpen}
                                                      className={
                                                         menuStyles.subMenu
                                                      }
                                                      activeClassName={
                                                         menuStyles.activeSub
                                                      }
                                                      to={item.url}
                                                   >
                                                      {item.title}
                                                   </Link>
                                                </li>
                                             )
                                          )}
                                    </>
                                 )}
                              </li>
                           ))}
                     </ul>
                  </div>
               </nav>
            </React.Fragment>
         )}
      </ThemeContext.Consumer>
   )
}
