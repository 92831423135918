import { useEffect, useState } from 'react'

export const useSize = () => {
  const [size, setSize] = useState({
    height: undefined,
    width: undefined,
    isMobile: null,
    isMobLandscape: null,
    isTablet: null,
    isDescktop: null,
    videoSize: null,
    vh: null,
  })

  useEffect(() => {
    //console.log('isMobile', isMobile)
    const handleResize = () => {
      //console.log('is loaded', window.innerHeight)
      const height = window.innerHeight
      const width = window.innerWidth
      const media = window.matchMedia
      setSize({
        width,
        height,
        isMobile: media('only screen and (max-width: 760px)').matches,
        isMobLandscape: media(
          'only screen and (max-height: 415px) and (orientation: landscape)'
        ).matches,
        isTablet: media('only screen and (max-width: 900px)').matches,
        isDescktop: media('only screen and (min-width: 901px)').matches,
        videoSize: width <= 760 ? 220 : 580,
        vh: height * 0.01,
      })
    } // Add event listener

    window.addEventListener('resize', handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, [size.width, size.height])

  return {
    ...size,
  }
}
