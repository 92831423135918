export const data = {
  name: 'Esteban Vicente',
  slug: '/esteban-vicente/biography',
  section: 'esteban-vicente',
  sublinks: [
    {
      name: 'Artwork',
      slug: '/esteban-vicente/artwork/1',
    },
    {
      name: 'Chronology',
      slug: '/esteban-vicente/chronology',
    },
    {
      name: 'Biography',
      slug: '/esteban-vicente/biography',
    },
    {
      name: 'Bibliography',
      slug: '/esteban-vicente/bibliography',
    },
    {
      name: 'Content',
      slug: '/esteban-vicente/content',
    },
  ],
}
