import React from 'react'
import containerStyles from './container.module.scss'

export default function Container({ children, oneColumn }) {
  return (
    <div
      className={
        oneColumn ? containerStyles.oneColumn : containerStyles.container
      }
    >
      {children}
    </div>
  )
}
