import React from 'react'
import hamburgerStyles from './hamburguer.module.scss'
import ThemeContext from '../../context/ThemeContext'

export default function Hamburguer({ onClick, hasBackground, styles }) {
  const toggleMenu = () => {
    onClick && onClick()
  }

  return (
    <ThemeContext.Consumer>
      {theme => (
        <div
          role="button"
          tabIndex={0}
          id={hamburgerStyles.menuToggle}
          className={`
            ${theme.open ? hamburgerStyles.open : hamburgerStyles.close} 
            ${hasBackground ? '' : hamburgerStyles.light}
            ${!styles ? '' : styles}
          `}
          onClick={toggleMenu}
          onKeyDown={toggleMenu}
        >
          <div id={hamburgerStyles.hamburger}>
            <span></span>
            <span></span>
          </div>
          <div id={hamburgerStyles.cross}>
            <span></span>
            <span></span>
          </div>
        </div>
      )}
    </ThemeContext.Consumer>
  )
}
