import React, { useState, useEffect, useRef } from 'react'
import Header from '../header/header.component'
import Footer from '../footer/footer.component'
import layoutStyles from './layout.module.scss'
import { useStaticQuery, graphql } from 'gatsby'
import ThemeContext from '../../context/ThemeContext'
import { Helmet } from 'react-helmet'
import Contact from '../contact/contact.component'
import Img from 'gatsby-image'
import { useSize } from '../../hooks/size.hook'

import './layout.style.css'

export default function Layout({
   children,
   goBack,
   title = '',
   description,
   canonical,
   showContact,
   showFooter,
   secondary,
   menuData,
   className,
   colorHeader,
   isMenuWhite,
   backgroundImageLayout,
   noBackgorund,
   hideStudioIcon,
   to,
   handleAudio,
   isMutted,
   name,
   ...otherProps
}) {
   const [canonicalUrl, setCanonicalUrl] = useState('')
   const [isVh, setIsVh] = useState(null)
   const { vh } = useSize()
   const wrapperRef = useRef(null)
   //console.log('------------- vh -------------', vh, 'isVh', isVh)

   useEffect(() => {
      const wrapper = wrapperRef.current
      wrapper.style.setProperty('--vh', `${vh}px`)
      setIsVh(vh)

      setCanonicalUrl(window.location.href)
   }, [vh])

   const data = useStaticQuery(graphql`
      query {
         site {
            siteMetadata {
               title
               description
               url
               author
            }
         }
      }
   `)
   return (
      <React.Fragment>
         <Helmet>
            <title>
               {title
                  ? title + ' - ' + data.site.siteMetadata.title
                  : data.site.siteMetadata.title}
            </title>
            <meta
               name='description'
               content={
                  description ? description : data.site.siteMetadata.description
               }
            />
            <link rel='canonical' href={canonical || canonicalUrl} />
            <meta name='author' content={data.site.siteMetadata.author} />
         </Helmet>

         <ThemeContext.Consumer>
            {theme => (
               <div
                  className={`
              ${layoutStyles.container} 
              ${theme.open ? layoutStyles.hidden : ''} 
              ${className ? className : ''}
            `}
                  ref={wrapperRef}
               >
                  <div
                     className={`${
                        theme.open ? layoutStyles.bg : layoutStyles.nobg
                     }`}
                  />

                  <div className={layoutStyles.content}>
                     <Header
                        secondary={secondary}
                        goBack={goBack}
                        currentPage={name}
                        to={to}
                        isMenuWhite={isMenuWhite}
                        menuData={menuData}
                        hasBackground={otherProps.hasBackground}
                        colorHeader={colorHeader}
                        isMoving={otherProps.isMoving}
                        noBackgorund={noBackgorund}
                        hideStudioIcon={hideStudioIcon}
                        handleAudio={handleAudio}
                        isMutted={isMutted}
                     />

                     <div
                        className={`${
                           theme.open ? layoutStyles.blur : 'normal-layout'
                        } ${isVh ? layoutStyles.show : layoutStyles.none}`}
                     >
                        {/*
                         * If have haveBackground else if need a space for secondary menu
                         */}
                        {backgroundImageLayout ? (
                           <div
                              className={`${layoutStyles.background} 
                      ${otherProps.isMoving ? layoutStyles.small : ''}
                    `}
                           >
                              <Img
                                 fixed={backgroundImageLayout}
                                 objectFit='cover'
                                 objectPosition='50% 50%'
                                 alt=''
                                 style={{ width: '100%', height: '100%' }}
                                 fadeIn
                              />
                           </div>
                        ) : secondary ? (
                           <div className={layoutStyles.secondaryHeaderSpace} />
                        ) : null}
                        {children}
                     </div>
                     {showContact && <Contact />}
                     {showFooter && <Footer />}
                  </div>
               </div>
            )}
         </ThemeContext.Consumer>
      </React.Fragment>
   )
}
