import { useState, useEffect } from 'react'

export const useScroll = () => {
  const [scrolling, setScrolling] = useState(false)
  const [scrollTop, setScrollTop] = useState(0)
  const [isTotalScroll, setTotalScroll] = useState(true)
  const [isInBottom, setInBottom] = useState(false)
  const [isInFooter, setInFooter] = useState(false)
  const [isScrolling, setIsScrolling] = useState(false)

  useEffect(() => {
    setIsScrolling(false)

    const onScroll = e => {
      /*
       * Current Scroll position
       */
      setScrollTop(e.target.documentElement.scrollTop)
      /*
       * False is scroll up(^), true is scroll down
       */
      setScrolling(e.target.documentElement.scrollTop > scrollTop)
      let footerHeight = document.querySelector('footer')
      footerHeight = footerHeight ? footerHeight.scrollHeight - 200 : 300
      //console.log('footerHeight', footerHeight)

      const totalScroll = window.innerHeight + window.pageYOffset
      const inBottom = totalScroll >= document.body.offsetHeight
      const inFooter = totalScroll >= document.body.offsetHeight - footerHeight

      setInBottom(inBottom)
      setInFooter(inFooter)
      setTotalScroll(inFooter || scrollTop < 150)

      /*
       *  Scroll event is activate
       **/
      window.requestAnimationFrame(function () {
        setIsScrolling(true)
        //console.log('scroll')
      })
    }

    window.addEventListener('scroll', onScroll)

    return () => {
      // Clear our timeout throughout the scroll
      window.removeEventListener('scroll', onScroll)
    }
  }, [scrolling, scrollTop, isScrolling, isInFooter])

  return {
    scrolling,
    scrollTop,
    isTotalScroll,
    isInBottom,
    isInFooter,
    isScrolling,
  }
}
