import React, { useContext } from 'react'
import IconX from './close.svg'
import closeStyles from './close.module.scss'
import ThemeContext from '../../context/ThemeContext'

export default function Close({ hasBackground }) {
  const context = useContext(ThemeContext)
  const callAction = () => {
    typeof context.goBack === 'function'
      ? context.goBack()
      : window.history.back()
  }
  return (
    <div
      role="button"
      tabIndex={0}
      onClick={callAction}
      className={`${closeStyles.close} ${
        hasBackground ? '' : closeStyles.light
      }`}
      onKeyDown={callAction}
    >
      <IconX className={closeStyles.icon} />
    </div>
  )
}
