import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import menuSecondaryStyles from './menu.secondary.module.scss'
import { data } from './menu.secondary.data.js'

export default function MenuSecondary({
  menuData = data,
  hasBackground,
  colorHeader,
}) {
  const [isInMenu, setIsInMenu] = useState(false)
  const [current, setCurrent] = useState(-1)
  const elements = menuData.childrenMenuItems

  useEffect(() => {
    /**
     * Clone and review links and aply hidden className
     * */
    const links = [...elements]
    console.log('links', links)
    links.push({ title: menuData.title, url: menuData.url })
    const curr = links.findIndex(
      element => element.url === window.location.pathname
    )
    console.log('current', curr, window.location.pathname)
    setIsInMenu(curr !== -1 ? true : false)
    setCurrent(curr)
  }, [elements, menuData.title, menuData.url, isInMenu, current])
  return (
    <div
      className={`
        ${menuSecondaryStyles.navigation} 
        ${
          hasBackground && colorHeader !== '#fcf6f3'
            ? menuSecondaryStyles.light
            : ''
        }
      `}
    >
      <Link
        className={`
          ${menuSecondaryStyles.principal} 
          ${
            elements?.length > 2
              ? isInMenu
                ? menuSecondaryStyles.hidden
                : ''
              : menuSecondaryStyles.lessTwo
          }
        `}
        to={menuData.url}
      >
        <h1>{menuData.title}</h1>
      </Link>

      {elements?.length > 2 && (
        <div className={menuSecondaryStyles.elements}>
          {elements.map((element, key) => (
            <Link
              key={key}
              title={element.title}
              to={element.url}
              className={current === key ? menuSecondaryStyles.active : ''}
              activeClassName={menuSecondaryStyles.active}
            >
              {element.title}
            </Link>
          ))}
        </div>
      )}
    </div>
  )
}
